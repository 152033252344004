<template>
    <div>
        <!--form add & update adverts-->
        <ek-dialog
            ref="dialog"
            @ok="submitForm"
            @close="resetForm"
            :isEdit="!!advertDto.id"
            :title="advertDto.id ? 'تعديل اعلان' : 'اضافة اعلان'"
            @delete="deleteAd(advertDto.id)"
            @search="setSearch"
            btnText="اعلان جديد"
            placeholder="ابحث عن اعلان"
        >
            <template #body>
                <div>
                    <validationObserver ref="classForm">
                        <b-form>
                            <b-form-group
                                label="نوع الاعلان"
                                v-slot="{ AdStatus }"
                            >
                                <b-form-radio-group
                                    id="radio-group-2"
                                    :aria-describedby="AdStatus"
                                    name="radio-sub-component"
                                    v-model="advertDto.type"
                                >
                                    <b-form-radio value="Image"
                                        >صورة</b-form-radio
                                    >
                                    <b-form-radio value="Video"
                                        >فيديو</b-form-radio
                                    >
                                </b-form-radio-group>
                            </b-form-group>
                            <ek-input-text
                                v-model="advertDto.title"
                                name=" عنوان الإعلان"
                                label=" عنوان الإعلان"
                                :rules="[
                                    {
                                        type: 'required',
                                        message: ' عنوان الإعلان مطلوب',
                                    },
                                ]"
                                placeholder="عنوان الإعلان مطلوب"
                            ></ek-input-text>

                            <ek-input-text
                                name="  ترتيب الأعلان"
                                label="  ترتيب الإعلان"
                                ref="order"
                                type="number"
                                v-model="advertDto.priority"
                                placeholder=" ادخل ترتيب الإعلان "
                                :rules="[
                                    {
                                        type: 'required',
                                        message: 'اضافة ترتيب الإعلان مطلوب',
                                    },
                                ]"
                            ></ek-input-text>

                            <ek-date-picker
                                :rules="[
                                    {
                                        type: 'required',
                                        message: 'يرجى ادخال بداية الإعلان ',
                                    },
                                ]"
                                name="date"
                                placeholder="ادخل بداية الاعلان"
                                label=" بداية الإعلان"
                                v-model="advertDto.startDate"
                            ></ek-date-picker>

                            <ek-date-picker
                                :rules="[
                                    {
                                        type: 'required',
                                        message: 'يرجى ادخال نهاية الإعلان ',
                                    },
                                ]"
                                name="dataa"
                                placeholder="ادخل نهاية الاعلان"
                                label=" نهاية الإعلان"
                                v-model="advertDto.endDate"
                            ></ek-date-picker>
                            <div v-if="!advertDto.id">
                                <label v-if="advertDto.type == 'Image'"
                                    >صورة الإعلان</label
                                >
                                <ek-input-image
                                    v-if="advertDto.type == 'Image'"
                                    :image="advertDto.url == ''"
                                    name="img"
                                    ref="imgFile"
                                    @input="uploadAdFile($event)"
                                >
                                    <h5>اسحب الملف او انقر للتحميل</h5>
                                </ek-input-image>
                            </div>
                            <div
                                v-if="advertDto.id && advertDto.type == 'Image'"
                            >
                                <label>الصورة الحالية</label>
                                <img
                                    class="w-100 h-100 mt-2"
                                    :src="
                                        $store.getters['app/domainHost'] +
                                        '/' +
                                        advertDto.url
                                    "
                                />
                            </div>
                            <div
                                v-if="advertDto.id && advertDto.type == 'Video'"
                                class="position-relative"
                            >
                                <div
                                    v-if="isEditCover != true"
                                    class="position-absolute"
                                    :style="{
                                        'z-index': '999',
                                        left: '-13px',
                                        top: '40px',
                                    }"
                                >
                                    <b-dropdown
                                        size="lg"
                                        variant="link"
                                        toggle-class="text-decoration-none"
                                        no-caret
                                    >
                                        <template #button-content>
                                            <div class="rounded-circle">
                                                <unicon
                                                    name="ellipsis-v"
                                                    fill="#005baa"
                                                ></unicon>
                                            </div>
                                        </template>
                                        <b-dropdown-item
                                            href="#"
                                            ref="myUploader"
                                            @click="storeCoverAdFile"
                                        >
                                            <unicon
                                                name="edit-alt"
                                                fill="#005baa"
                                                style="width: 25px"
                                            ></unicon>
                                            <span>تعديل ملف</span>
                                        </b-dropdown-item>
                                    </b-dropdown>
                                </div>

                                <label>غلاف الفيديو </label>
                                <ek-input-image
                                    v-if="
                                        advertDto.type == 'Video' &&
                                        isEditCover == true
                                    "
                                    ref="imgThumbFile"
                                    label=" غلاف الدرس "
                                    name="صور "
                                    @input="storeCoverAdFile"
                                >
                                    <h5>انقر لتحميل غلاف</h5>
                                </ek-input-image>
                                <img
                                    v-if="
                                        advertDto.type == 'Video' &&
                                        !isEditCover == true
                                    "
                                    class="w-100 h-100 mt-2"
                                    :src="
                                        $store.getters['app/domainHost'] +
                                        '/' +
                                        advertDto.thumbnail
                                    "
                                />
                            </div>
                            <div class="position-relative" v-if="advertDto.id">
                                <label v-if="advertDto.type == 'Video'"
                                    >فيديو الإعلان</label
                                >
                                <div
                                    v-if="isEditVedio != true"
                                    class="position-absolute"
                                    :style="{
                                        'z-index': '999',
                                        left: '-13px',
                                    }"
                                >
                                    <b-dropdown
                                        size="lg"
                                        variant="link"
                                        toggle-class="text-decoration-none"
                                        no-caret
                                    >
                                        <template #button-content>
                                            <div class="rounded-circle">
                                                <unicon
                                                    name="ellipsis-v"
                                                    fill="#005baa"
                                                ></unicon>
                                            </div>
                                        </template>
                                        <b-dropdown-item
                                            href="#"
                                            ref="myUploader"
                                            @click="storeFile"
                                        >
                                            <unicon
                                                name="edit-alt"
                                                fill="#005baa"
                                                style="width: 25px"
                                            ></unicon>
                                            <span>تعديل ملف</span>
                                        </b-dropdown-item>
                                    </b-dropdown>
                                </div>

                                <video
                                    v-if="
                                        newFileUrl &&
                                        advertDto.type == 'Video' &&
                                        isEditVedio != true
                                    "
                                    controls
                                    autoplay
                                    class="h-100 w-100 rounded"
                                    style="object-fit: cover"
                                >
                                    <source
                                        :src="newFileUrl"
                                        type="video/mp4"
                                    />
                                </video>
                                <ek-input-image
                                    v-if="
                                        advertDto.type == 'Video' &&
                                        isEditVedio == true
                                    "
                                    class="mt-1"
                                    ref="imgFile"
                                    label=" الملف الدرس "
                                    name="الملف "
                                    @input="storeAdFile"
                                >
                                    <h5>انقر لتحميل الملف</h5>
                                </ek-input-image>
                            </div>
                            <div v-if="!advertDto.id">
                                <label v-if="advertDto.type == 'Video'"
                                    >فيديو الإعلان</label
                                >

                                <ek-input-image
                                    v-if="advertDto.type == 'Video'"
                                    ref="imgThumbFile"
                                    label=" غلاف الدرس "
                                    name="صور "
                                    @input="storeCoverAdFile"
                                >
                                    <h5>انقر لتحميل غلاف</h5>
                                </ek-input-image>
                                <!-- {{ url }} -->
                                <ek-input-image
                                    v-if="advertDto.type == 'Video'"
                                    class="mt-1"
                                    ref="imgFile"
                                    label=" الملف الدرس "
                                    name="الملف "
                                    @input="storeAdFile"
                                >
                                    <h5>انقر لتحميل الملف</h5>
                                </ek-input-image>
                            </div>
                        </b-form>
                    </validationObserver>
                </div>
            </template>
        </ek-dialog>
    </div>
</template>

<script>
import { mapState, mapActions, mapMutations, mapGetters } from "vuex";
import { fileWindowApi } from "@core/utils/uploader";
export default {
    data() {
        return {
            file: null,
            thumbFile: null,
            isEdit: false,
            isEditCover: false,
            isEditVedio: false,
        };
    },

    computed: {
        ...mapState({
            advertDto: (state) => state.adverts.advertDto,
            isDialogOpen: (state) => state.adverts.isDialogOpen,
            url: (state) => state.subjects.url,
            newFileUrl: (state) => state.adverts.newFileUrl,
        }),
        ...mapGetters(["getUrl"]),
    },
    methods: {
        storeCoverAdFile(event) {
            if (this.advertDto.id) {
                this.advertDto.NewThumbnail = event.file;
            } else {
                this.advertDto.thumbFile = event.file;
            }

            this.isEdit = true;
            this.isEditCover = true;
        },
        storeFile() {
            this.isEdit = true;
            this.isEditVedio = true;
        },
        fileWindowApi,
        storeAdFile(event) {
            this.isEdit = true;
            this.isEditVedio = true;
            console.log("ffffffffff");
            if (this.advertDto.id) {
                this.advertDto.NewVideo = event.file;
                this.advertDto.DeleteThumbFile = true;
            } else {
                this.advertDto.file = event.file;
            }
            let extensions = event.file.type.split("/");
            console.log(extensions);
            if (extensions[1] == "mp4") {
                this.extensionOfFile = "Video";
                console.log(this.extensionOfFile);
            }
        },
        submitForm() {
            this.$refs.classForm.validate().then((suc) => {
                if (suc) {
                    if (this.advertDto.type == "Video") {
                        this.advertDto.url = null;
                    }
                    if (this.advertDto.id) {
                        this.updateAds(this.advertDto).then(() =>
                            this.getAdsList()
                        );
                        this.$refs.dialog.close();
                    } else {
                        this.addAds({ ...this.advertDto, url: this.url })
                            .then((data) => {
                                console.log(data);
                                this.$refs.dialog.close();
                            })
                            .catch(() => {});
                    }
                }
            });
        },
        updateAdsDate($event) {
            this.Update_Ads_Dto_Date($event);
        },
        uploadAdFile(event) {
            if (event && event.file) {
                this.addFile({ file: event.file, folderName: "Ad" }).then(
                    (res) => {
                        this.advertDto.url = res.data;
                    }
                );
            }
        },
        deleteAd(id) {
            this.deleteAd(id);
            this.$refs.dialog.close();
        },
        resetForm() {
            this.$refs.classForm.reset();
            // this.$refs.imgFile.reset();
            this.$store.commit("SET_ADS_DTO");
            this.$store.commit("IS_DIALOG_OPEN", false);
            this.newFileUrl = "";
        },
        setSearch(query) {
            this.$store.commit("Set_Search_Dto", {
                keys: ["title", "startDate", "endDate"],
                query,
            });
        },
        ...mapActions([
            "addAds",
            "updateAds",
            "deleteAd",
            "addFile",
            "getFileLink",
            "getAdsList",
        ]),
        ...mapMutations(["Update_Ads_Dto_Date"]),
    },
    watch: {
        isDialogOpen(nv) {
            if (nv) {
                this.$refs.dialog.open();
            }
        },
    },
};
</script>
